.Home {
  text-align: center;
  height: 100vh;
  width: 100vw;
}
.canvas {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.centerCanvas {
  display: flex;
  height: 100vh;
  width: 1000px;
  max-width: 1000px;
  flex-direction: column;
  align-items: center;
  /* overflow: hidden; */
}
.rightSideCanvas {
  /* width: 20vw; */
  width: 350px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 30vh;
  align-items: end;
  justify-content: space-between;
}

.leftSideCanvas {
  /* width: 20vw; */
  width: 350px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* medium - tablet styles */
@media screen and (max-width: 1700px) {
  .centerCanvas {
    display: flex;
    height: 100vh;
    width: 100vw;
    max-width: 880px;
    flex-direction: column;
    align-items: center;
  }
  .rightSideCanvas {
    display: none;
  }
  .leftSideCanvas {
    display: none;
  }
}
