.PlayPointsContainer {
  color: white;
  height: 85%;
  max-height: 820px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: center;
  font-family: "Noto Serif JP", serif;
  -webkit-text-stroke-color: black;
  /* position: fixed; */
  /* bottom: 50%; */
}

/* medium - tablet styles */
@media screen and (max-width: 1000px) {
}

/* small - mobile styles */
@media screen and (max-width: 600px) {
}

/* extra small styles */
@media screen and (max-width: 400px) {
}
.inc {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  width: 60px;
  top: 0;
}

.dec {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  width: 60px;
  bottom: 0;
}
.CircleContainer {
  /* background-color: #131219; */
  background-color: rgba(0, 0, 0, 0.6);
  outline: 7px ridge rgba(0, 0, 0, 0.6);
  border-radius: 2rem;
  height: 80%;
  max-width: 120px;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1em;
  padding-left: 4em;
}

.IncDecContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.points {
  font-size: 30px;
  height: 80px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding: 0 0.2em;
}

.pointsContainer {
  background-color: rgba(0, 0, 0, 0.8);
  outline: 7px ridge rgba(0, 0, 0, 0.6);
  border-radius: 2rem;
  /* position: fixed; */
  bottom: 20%;
  left: 30%;
  height: "200px";
  width: "200px";
  display: flex;
  justify-content: center;
  align-items: center;
}

.upArrow {
  background-color: rgba(0, 0, 0, 0.8);
  outline: 7px ridge rgba(0, 0, 0, 0.6);
  height: "200px";
  width: "200px";
}

.downArrow {
  background-color: rgba(0, 0, 0, 0.8);
  outline: 7px ridge rgba(0, 0, 0, 0.6);
  height: "200px";
  width: "200px";
}

.circles {
  height: 100%;
  position: relative;
  /* top: 5%; */
  user-select: none;
  bottom: 7%;
  flex-direction: column-reverse;
  display: flex;
}

.circle {
  background: radial-gradient(
    circle at -1% 57.5%,
    rgb(19, 170, 82) 0%,
    rgb(0, 102, 43) 90%
  );
  /* background-color: green; */
  outline: 5px ridge rgba(0, 0, 0, 0.9);
  border-radius: 2rem;
  display: flex;
  margin-top: 30%;
  width: 45px;
  height: 45px;
  font-size: 1.8em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.circleFaded {
  background-color: rgb(0, 255, 0, 0.1);
  outline: 5px ridge rgba(0, 0, 0, 0.9);
  border-radius: 2rem;
  display: flex;
  margin-top: 30%;
  width: 45px;
  height: 45px;
  font-size: 1.8em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.nextTurnContainer {
  user-select: none;
  background: radial-gradient(
    circle at -1% 57.5%,
    rgb(19, 170, 82) 0%,
    rgb(0, 102, 43) 90%
  );
  /* background-color: green; */
  outline: 5px ridge rgba(0, 0, 0, 0.9);
  border-radius: 2rem;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.endTurnContainer {
  user-select: none;
  background: linear-gradient(to right, rgb(5, 117, 230), rgb(2, 27, 121));
  /* background-color: blue; */
  outline: 5px ridge rgba(0, 0, 0, 0.9);
  border-radius: 2rem;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.buttonText {
  font-size: 14px;
  font-family: "Noto Serif JP", serif;
  color: white;
  /* width: 100%; */
}
