/* default styles */
.LeaderContainerHome {
  height: 100vh;
  width: 50vw;
  left: 10%;
}
.LeaderImageHome {
  width: 50vw;
  min-width: 1000px;
  bottom: 0%;
}
.cardHover {
  width: 410px;
  height: 600px;
}

.cardSizeInPreview {
  aspect-ratio: 110 / 150;
  max-height: 150px;
}
@media screen and (max-width: 2000px) {
  .cardSizeInPreview {
    aspect-ratio: 110 / 150;
    max-height: 120px;
  }
}
/* medium - tablet styles */
@media screen and (max-width: 1600px) {
  .cardHover {
    width: 246px;
    height: 360px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .LeaderImageHome {
    animation: slidein infinite 1.5s ease-in-out alternate;
  }
}

/* animations */
@keyframes slidein {
  from {
    margin-top: 5vh;
  }

  to {
    margin-top: 10vh;
  }
}
