.box {
  z-index: 0;
  border-radius: "10px";
  border: 4px solid transparent;
}
.box:hover {
  border-radius: 10px;
  border: 4px solid #0000;
  background: linear-gradient(#09203f 0%, rgba(60, 105, 134, 1) 100%)
      padding-box,
    linear-gradient(var(--angle), #a166ab, #5073b8, #1098ad, #07b39b, #6fba82)
      border-box;

  animation: 0.5s rotate linear infinite;
}
.box2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -0.2em;
  margin-right: -0.2em;
  border-radius: 10px;
  border: 4px solid #0000;
  background: linear-gradient(#09203f 0%, rgba(60, 105, 134, 1) 100%)
      padding-box,
    linear-gradient(var(--angle), #da2d0f, #ec403a, #f0a1a1, #ff0000) border-box;

  animation: 0.5s rotate linear infinite;
}

.aura {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -0.2em;
  margin-right: -0.2em;
  border-radius: 10px;
  border: 4px solid #0000;
  animation: 2.5s aura linear infinite;
}
.bane {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -0.2em;
  margin-right: -0.2em;
  border-radius: 10px;
  border: 4px solid #0000;
  animation: 2.5s bane linear infinite;
}
.ward {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -0.2em;
  margin-right: -0.2em;
  border-radius: 10px;
  border: 4px solid #0000;
  animation: 2.5s ward linear infinite;
}

@keyframes aura {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 200, 124);
  }
  to {
    box-shadow: 0 0 10px 10px rgb(252, 207, 49);
  }
}
@keyframes bane {
  from {
    box-shadow: 0 0 10px -10px rgb(100, 0, 0);
  }
  to {
    box-shadow: 0 0 10px 10px rgb(200, 0, 0);
  }
}
@keyframes ward {
  from {
    box-shadow: 0 0 10px -10px rgb(182, 244, 146);
  }
  to {
    box-shadow: 0 0 10px 10px rgb(51, 139, 147);
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.none {
  height: "160px";
  width: "115px";
  background-color: "#131219";
  border-radius: "10px";
  border: 4px solid transparent;
}
