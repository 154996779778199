* {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -webkit-user-drag: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

body {
  /* filter: saturate(1.15) hue-rotate(345deg); */
  /* overflow: hidden; */
}
html,
#root {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: black;
  overflow: clip;
}

/* #root * {
  display: flex;
  align-items: center;
  justify-content: center;
} */

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 1s ease 0.3s forwards;
}

canvas:active {
  cursor: grabbing;
}
