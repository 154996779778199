.atkInputNum {
  position: absolute;
  top: 125px;
  right: 65px;
  z-index: 100;
  width: 40px;
  font-size: 20px;
  font-family: "Noto Serif JP", serif;
  text-align: center;
  background-color: transparent;
  color: transparent;
  border: none;
  border-radius: 0%;
  outline: none;
}

.defInputNum {
  position: absolute;
  top: 125px;
  left: 65px;
  z-index: 100;
  width: 40px;
  font-size: 20px;
  font-family: "Noto Serif JP", serif;
  text-align: center;
  background-color: transparent;
  color: transparent;
  border: none;
  outline: none;
  transform: rotateY(180deg);
}

.counterInput {
  position: absolute;
  top: 50px;
  left: 65px;
  z-index: 100;
  width: 40px;
  font-size: 20px;
  font-family: "Noto Serif JP", serif;
  text-align: center;
  background-color: transparent;
  color: transparent;
  border: none;
  outline: none;
  transform: rotateY(180deg);
}
